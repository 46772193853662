button {
  background: none;
  border: none;
}

.App {
  min-height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App-logo {
  position: fixed;
  top: 2vh;
  left: 2vh;
  height: 10vh;
}

.Full {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-question {
  position: relative;
  overflow: hidden;
  top: 25vh;
  height: 20vh;
  padding: 0 15vw;
  font-size: 32px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.App-teams {
  position: relative;
  top: 35vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.App-teams .background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App-team {
  position: relative;
  width: 35%;
  margin-right: 3%;
  margin-left: 3%;
  margin-bottom: 5vh;
  height: 8vh;
  border-radius: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 4px solid #fff;
  display: flex;
  justify-content: space-between;
}

.App-team::after {
  content: "";
  position: absolute;
  right: -4px;
  top: -4px;
  border-radius: 8vh;
  height: 8vh;
  width: 8vh;
  border: 4px solid #fff;
}

.App-team span {
  flex: 1;
  text-align: left;
  padding-left: 10px;
  font-size: 2rem;
  color: #fff;
  text-transform: capitalize;
  line-height: 8vh;
}

.App-team img:first-child {
  height: calc(125% + 8px);
  margin-left: -4px;
}

.App-team div {
  height: calc(100% + 8px);
  margin-right: -4px;
}

.App-team img:last-child {
  height: 100%;
}

.App-answer-btns {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 3vw 8vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.App-answer-btns .btn {
  width: 10vw;
  height: 10vw;
}

.App-answer-btns .btn img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.Q-nav-btns {
  position: fixed;
  width: calc(100% - 50vw);
  right: 0;
  top: 0;
  padding: 6vh 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Q-nav-btns .btn {
  width: 15vh;
  height: 15vh;
}

.Q-nav-btns .btn:first-child {
  margin-top: 2vh;
  width: 15vh;
  height: 15vh;
}

.Q-nav-btns .btn img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.play-btn img {
  height: 20vh;
}

.Q-counter {
  position: fixed;
  height: 18vh;
  top: 5vh;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 1s;
}

.Q-counter > div {
  height: 18vh !important;
  width: 18vh !important;
}

.Q-counter svg {
  display: none;
}

.Game-intro {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.Game-intro video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Q-answer {
  position: relative;
  top: 30vh;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: left;
  font-size: 32px;
}

.Q-answer ul {
  list-style: square;
  line-height: 1.4;
}

.Game-winner {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.Game-winner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.TeamPage {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.TeamPage button {
  margin: 2vh;
}

.TeamPage button:disabled {
  opacity: 0.5;
}

.TeamPage img {
  height: 10vh;
  object-fit: contain;
}

.btn.buzz img {
  height: 30vh;
}

.buzz-container {
  display: flex;
  flex-direction: column;
}

.team-image {
  position: fixed;
  height: 15vh !important;
  bottom: 2vh;
  right: 2vh;
}

.reset-btn {
  position: fixed;
  height: 10vh;
  width: 10vh;
  top: 10vh;
  left: 30vw;
  border-radius: 100%;
  color: #fff;
  border: 2px solid #fff;
}

.show-winner-btn {
  position: fixed;
  height: 10vh;
  width: 10vh;
  top: 10vh;
  left: 20vw;
  border-radius: 100%;
  color: #fff;
  border: 2px solid #fff;
}

.show-winner-btn:disabled {
  opacity: 0.5;
}

.Teams-row {
  display: flex;
  flex-direction: row;
}

.Team-circle {
  height: 10vh;
  margin-right: 5vh;
  margin-top: 5vh;
}

.Team-circle img {
  height: 100%;
}
